import React, { useState } from "react";
import Notification from "../Notification";
import "bootstrap/dist/css/bootstrap.min.css";
//import { Button } from "react-bootstrap";
import About from "../components/About";
import Modal from "react-modal";
import Header from "../components/Header";
import PosaljiNotif from "../components/PosaljiNotif";
import { getDatabase, ref, set } from "firebase/database";
import firebaseConfig from "../firebaseConfig";
import { initializeApp } from "firebase/app";

Modal.setAppElement("#root"); // replace '#root' with the id of your app's root element
const currentDate = new Date();
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const Settings = () => {
  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  const [instructionsModalIsOpen, setInstructionsModalIsOpen] = useState(false);
  const [posaljiNotifIsOpen, setPosaljiNotifIsOpen] = useState(false);

  const isUserDataCookieSet = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("userData"))
      ?.split("=")[1];
    return cookieValue && cookieValue !== "";
  };

  const email = document.cookie
    .split("; ")
    .find((row) => row.startsWith("userData"))
    ?.split("=")[1];

  const updateEmailPlus = (email) => {
    const praviMejl = email.replace(/\+/g, ".");
    return praviMejl;
  };
  const handleNotificationSubmit = (id, title, message) => {
    const formattedDateTime = currentDate.toLocaleDateString("hr-HR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // Use 24-hour format
    });
    const notificationRef = ref(db, "administracija/notifikacije/" + id); // Path in Firebase
    set(notificationRef, {
      id: id,
      naslov: title,
      poruka: message,
      vrijeme: formattedDateTime,
    })
      .then(() => {
        console.log("Notification data saved successfully!");
        setPosaljiNotifIsOpen(false); // Close modal after submission
      })
      .catch((error) => {
        console.error("Error saving notification data:", error);
      });
  };
  return (
    <div>
      <Header
        title="Settings"
        showBackButton={true}
        showSettingsButton={false}
      />
      <Notification />
      <div className="container">
        {email && <h3>E-mail: {updateEmailPlus(email)}</h3>}
        <div class="d-flex flex-column gap-2">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
            onClick={() => setPrivacyModalIsOpen(true)}
          >
            <i class="bi bi-shield-fill-exclamation p-1" />
            Privacy policy
          </button>
          <Modal
            className="modal-about2"
            isOpen={privacyModalIsOpen}
            onRequestClose={() => setPrivacyModalIsOpen(false)}
            contentLabel="Privacy Policy Modal"
          >
            <iframe
              src={`${process.env.PUBLIC_URL}/PrivacyPolicyEN.html`}
              title="Privacy Policy"
              width="100%"
              height="100%"
            ></iframe>
            <button
              class="btn btn-primary"
              onClick={() => setPrivacyModalIsOpen(false)}
            >
              Close
            </button>
          </Modal>
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
            onClick={() => setInstructionsModalIsOpen(true)}
          >
            <i class="bi bi-question-circle-fill p-1" />
            Instructions
          </button>
          <Modal
            className="modal-about2"
            isOpen={instructionsModalIsOpen}
            onRequestClose={() => setInstructionsModalIsOpen(false)}
            contentLabel="Instructions Modal"
          >
            <iframe
              src="https://eevent.hr/hdmblm2022/Page.aspx?ID=51"
              title="Instructions"
              width="100%"
              height="100%"
            ></iframe>
            <button
              class="btn btn-primary"
              onClick={() => setInstructionsModalIsOpen(false)}
            >
              Close
            </button>
          </Modal>
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
            onClick={() => setAboutModalIsOpen(true)}
          >
            <i class="bi bi-info-circle-fill p-1" />
            About
          </button>
          <Modal
            className="modal-about"
            isOpen={aboutModalIsOpen}
            onRequestClose={() => setAboutModalIsOpen(false)}
            contentLabel="About Modal"
          >
            <About />
            <button
              class="btn btn-primary"
              onClick={() => setAboutModalIsOpen(false)}
            >
              Close
            </button>
          </Modal>

          <button
            type="button"
            class={`btn btn-lg btn-block text-start ${
              isUserDataCookieSet() ? "btn-danger" : "btn-secondary"
            }`}
            disabled={!isUserDataCookieSet()}
            onClick={() => {
              document.cookie =
                "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              alert("Email address has been deleted.");
              window.location.reload();
            }}
          >
            <i class="bi bi-trash-fill p-1"></i>
            Delete email address
          </button>
          <button
            type="button"
            class="btn btn-warning btn-lg btn-block text-start"
            onClick={() => setPosaljiNotifIsOpen(true)}
          >
            <i class="bi bi-info-circle-fill p-1" />
            Posalji notifikaciju
          </button>
          <Modal
            className="modal-about"
            isOpen={posaljiNotifIsOpen}
            onRequestClose={() => setPosaljiNotifIsOpen(false)}
            contentLabel="Posalji notif test"
          >
            <PosaljiNotif onSubmit={handleNotificationSubmit} />
            <button
              className="btn btn-primary"
              onClick={() => setPosaljiNotifIsOpen(false)}
            >
              Close
            </button>
            {/* "Send" button is handled by the form inside PosaljiNotif */}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default Settings;
