import React, { useState } from "react";
//import { database, ref, set } from "../firebaseConfig";

const PosaljiNotif = ({ onSubmit }) => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent page reload on form submit
    if (onSubmit) {
      onSubmit(id, title, message); // Call onSubmit from parent with form data
    }
  };
  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="inputField1"
          enterKeyHint="ID"
          placeholder="ID"
          value={id}
          onChange={(e) => setId(e.target.value)} // Bind the input to state
        />
        <input
          type="text"
          id="inputField2"
          placeholder="Naslov notifikacije"
          value={title}
          onChange={(e) => setTitle(e.target.value)} // Bind the input to state
        />
        <textarea
          id="inputField3" // Fix duplicate ID
          placeholder="Text notifikacije"
          value={message}
          onChange={(e) => setMessage(e.target.value)} // Bind the textarea to state
        />
        <button type="submit">Send Notification</button>
      </form>
    </div>
  );
};

export default PosaljiNotif;
